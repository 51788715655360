export const capitalizeOnlyFirstLetter = string => {
  const stringToLowerCase = string.toLocaleLowerCase();
  return stringToLowerCase.charAt(0).toUpperCase() + string.slice(1);
};

export const getPluralSingularTextSeparated = textArray => {
  let text = '';

  if (textArray.length === 1) {
    text = textArray[0];
  } else if (textArray.length === 2) {
    text = textArray.join(' e ');
  } else if (textArray.length > 2) {
    text = textArray.join(', ').replace(/_([^_]*)$/, ' e ' + '$1');
  }

  return capitalizeOnlyFirstLetter(text);
};

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
