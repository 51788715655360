<template>
  <div id="surgical-note">
    <div class="breadcrumbs">
      <router-link
        to="/configuracoes-do-centro-cirurgico/modelos-de-anotacao"
        tag="span"
        class="link"
      >
        Modelos de {{ title }}
      </router-link>
      <ChevronRight class="chevron" />
      <span v-if="surgicalNote?.id">{{ surgicalNote.name }}</span>
      <span v-else>Novo modelo</span>
    </div>

    <b-container fluid class="content">
      <validation-observer v-slot="{ invalid }">
        <b-row class="content-data">
          <b-col cols="12">
            <b-form-group>
              <validation-provider name="name" rules="required">
                <label for="name">Título da {{ title }}</label>
                <b-form-input
                  id="name"
                  autocomplete="off"
                  debounce="500"
                  v-model="surgicalNote.name"
                  placeholder="Descrever"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="content-data">
          <b-col cols="12">
            <b-form-group>
              <validation-provider name="template" rules="required">
                <label for="template">
                  {{ capitalizeOnlyFirstLetter(firstTitle) }}
                </label>

                <VueEditor
                  v-if="showVueEditor"
                  id="template"
                  class="vue_edition"
                  v-model="surgicalNote.template"
                  :editorToolbar="customToolbar"
                  :placeholder="textAreaPlaceholder"
                />
                <TextArea
                  v-else
                  id="template"
                  autocomplete="off"
                  :value="surgicalNote.template"
                  @input="value => (surgicalNote.template = value)"
                  :placeholder="textAreaPlaceholder"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="content-data wrapper-button">
          <b-button class="mr-2" variant="outline-primary" @click="goBack">
            Cancelar
          </b-button>

          <b-button
            v-if="surgicalNote?.id"
            variant="primary"
            :disabled="invalid"
            @click="updateSurgicalNote"
          >
            Atualizar modelo de {{ firstTitle }}
          </b-button>

          <b-button
            v-else
            variant="primary"
            :disabled="invalid"
            @click="createSurgicalNote"
          >
            Salvar modelo de {{ firstTitle }}
          </b-button>
        </div>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { parseUserTypeToShow } from '@/utils/surgeryCenterHelper'
import { getCurrentUser } from '@/utils/localStorageManager'
import { capitalizeOnlyFirstLetter } from '@/utils/stringUtils'

export default {
  name: 'SurgicalNote',
  metaInfo: {
    title: 'Eyecare - Modelos de anotação/evolução'
  },
  components: {
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    VueEditor,
    TextArea: () => import('@/components/General/TextArea')
  },
  data: () => ({
    user: getCurrentUser(),
    surgicalNote: {
      name: null,
      template: null
    },
    id: null,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }]
    ]
  }),
  computed: {
    title() {
      return parseUserTypeToShow(this.user?.type)
    },
    showVueEditor() {
      return this.title === 'anotação cirúrgica'
    },
    firstTitle() {
      return this.title.split(' ')[0]
    },
    textAreaPlaceholder() {
      return `Descrever ${this.firstTitle}`
    }
  },
  methods: {
    capitalizeOnlyFirstLetter,
    parseUserTypeToShow,
    goBack() {
      this.$router.push(
        '/configuracoes-do-centro-cirurgico/modelos-de-anotacao'
      )
    },
    async findSurgicalNote() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findSurgical(this.id)
        this.surgicalNote = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async createSurgicalNote() {
      const isLoading = this.$loading.show()
      try {
        await this.api.createSurgical({
          ...this.surgicalNote,
          professional_id: this.user?.id
        })
        this.$toast.success(`Modelo de ${this.title} criado com sucesso`)
        this.goBack()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateSurgicalNote() {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateSurgical(this.surgicalNote.id, {
          ...this.surgicalNote
        })
        this.$toast.success(`Modelo de ${this.title} atualizado com sucesso`)
        this.goBack()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    '$route.params.id': {
      async handler(value) {
        if (!value) return
        this.id = value
        await this.findSurgicalNote()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#surgical-note {
  height: 100%;
  padding: 30px 24px;

  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .content {
    background-color: var(--neutral-000);
    border-radius: 8px;

    .content-data {
      padding: 10px 15px;

      .vue_edition {
        word-break: break-all;
        background-color: white;

        :deep(.ql-editor) {
          min-height: 50vh !important;
        }
      }

      &.wrapper-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
