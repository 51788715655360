export const parseUserTypeToShow = (type) => {
  switch (type) {
    case 'DOCTOR_MANAGER':
    case 'DOCTOR':
      return 'anotação cirúrgica'
    case 'NURSE':
    case 'DOCTOR_ANESTHETIST':
      return 'evolução'
    case 'TECHNICIAN':
      return 'anotação'
    default:
      return 'anotação cirúrgica'
  }
}

export const getSurgeryStatus = () => {
 return [
  'Reservado',
  'Confirmado',
  'Primeiro atendimento',
  'Admissão finalizada',
  'Pronto para cirurgia',
  'Em cirurgia',
  'Sala de recuperação',
  'Finalizado',
  'Faltou',
  'Cancelado'
 ]
}

export const getSurgeryLabelStatus = () => {
  return [
    { label: 'Reservado', value: 'STATUS_RESERVED' },
    { label: 'Confirmado', value: 'STATUS_CONFIRMED' },
    { label: 'Primeiro atendimento', value: 'STATUS_FIRST_CALL' },
    { label: 'Admissão finalizada', value: 'STATUS_ADMISSION_COMPLETED' },
    { label: 'Pronto para cirurgia', value: 'STATUS_READY_FOR_SURGERY' },
    { label: 'Em cirurgia', value: 'STATUS_IN_SURGERY' },
    { label: 'Sala de recuperação', value: 'STATUS_RECOVERY_ROOM' },
    { label: 'Finalizado', value: 'STATUS_FINISHED' },
    { label: 'Faltou', value: 'STATUS_MISSED' },
    { label: 'Cancelado', value: 'STATUS_CANCELED' }
  ]
}